export const initTestimonialsCarousel = () => {
  const peakSize = (window.outerWidth - 1490) / 2

  const settings = {
    type: 'carousel',
    autoplay: 8000,
    hoverpause: true,
    perView: 2,
    gap: 60,
    peek: {
      before: Math.max(peakSize, 90),
      after: Math.max(peakSize, 90)
    },
    breakpoints: {
      992: {
        perView: 1,
        gap: 15,
        peek: {
          before: 25,
          after: 25
        }
      }
    }
  }

  document.querySelectorAll('.testimonials-carousel').forEach(carousel => {
    if (carousel.querySelector('.slide').children.length > 0) {
      new Glide(carousel, settings).mount()
    }
  })
}

export const initIndustriesSliderCarousel = () => {
  const settings = {
    type: 'carousel',
    perView: 3,
    gap: 60,
    breakpoints: {
      992: {
        perView: 1,
        gap: 15,
        peek: {
          before: 25,
          after: 25
        }
      }
    }
  }

  document.querySelectorAll('.industries-slider').forEach(carousel => {
    if (carousel.querySelector('.slide').children.length > 0) {
      new Glide(carousel, settings).mount()
    }
  })
}

export const initHomeTestimonialsSliderCarousel = () => {
  const settings = {
    perView: 2,
    gap: 60,
    breakpoints: {
      992: {
        perView: 1,
        gap: 15,
        peek: {
          before: 25,
          after: 25
        }
      }
    }
  }

  document.querySelectorAll('.testimonials-slider').forEach(carousel => {
    if (carousel.querySelector('.slide').children.length > 0) {
      new Glide(carousel, settings).mount()
    }
  })
}

export const initTeamMembersCarousel = () => {
  const settings = {
    type: 'carousel',
    autoplay: 4000,
    hoverpause: true,
    perView: 7,
    perSwipe: '|',
    gap: 60,
    peek: {
      before: 100,
      after: 100
    },
    breakpoints: {
      768: {
        perView: 3,
        gap: 15,
        peek: {
          before: -40,
          after: -40
        }
      },
      1050: {
        perView: 3,
        gap: 15,
        peek: {
          before: 100,
          after: 100
        }
      },
      1200: {
        perView: 4,
        gap: 15,
        peek: {
          before: 100,
          after: 100
        }
      }
    }
  }

  document.querySelectorAll('.team-carousel').forEach(carousel => {
    if (carousel.querySelector('.member').children.length > 0) {
      new Glide(carousel, settings).mount()
    }
  })
}

export const initPrimaryCaseStudiesCarousel = () => {
  const settings = {
    type: 'slider',
    autoplay: false,
    hoverpause: true,
    perView: 1,
    gap: 0,
    perTouch: 1,
    peek: {
      before: 0,
      after: 0
    },
    breakpoints: {
      992: {
        swipeThreshold: false,
        dragThreshold: false
      }
    }
  }

  document.querySelectorAll('.one-slide-carousel').forEach(carousel => {
    const slider = new Glide(carousel, settings)
    if (carousel.classList.contains('with-indicators')) {
      slider.on('mount.before', () => {
        new Indicators(slider.selector).render()
      })
    }

    slider.mount()
  })
}

export const initTeamQuotesCarousel = () => {
  const settings = {
    type: 'carousel',
    autoplay: 4000,
    hoverpause: true,
    perView: 3,
    gap: 20,
    breakpoints: {
      768: {
        perView: 1,
        gap: 0
      }
    }
  }

  document.querySelectorAll('.team-quotes').forEach(carousel => {
    if (carousel.querySelector('.slide').children.length > 0) {
      const slider = new Glide(carousel, settings);

      slider.on('mount.before', () => {
        new Indicators(slider.selector).render()
      });

      slider.mount()
    }
  })
}

export const initTechnologiesCarousel = () => {
  const parent = document.querySelector('.technologies-carousel').parentNode
  console.log();
  const withPeek = parent.classList.contains('new-layout') ? {
    before: 25,
    after: 25
  } : {}
  const width = window.innerWidth
  const settings = {
    type: 'carousel',
    autoplay: 8000,
    hoverpause: true,
    perView: 3,
    gap: 40,
    breakpoints: {
      1080: {
        perView: 2,
        gap: 40
      },
      768: {
        perView: 1,
        gap: 15,
        peek: withPeek,
      }
    }
  }

  const disableCarousel = carousel => {
    carousel.classList.add('carousel-disabled')
  };

  document.querySelectorAll('.technologies-carousel').forEach(carousel => {
    if (carousel.querySelector('.slide').children.length > 0) {
      const slider = new Glide(carousel, settings)
      const sliderLength = slider.selector.querySelectorAll('.tool').length

      if (width <= 768 && sliderLength < settings['breakpoints'][768].perView) {
        disableCarousel(slider.selector);
      } else if (width <= 1080 && sliderLength < settings['breakpoints'][1080].perView) {
        disableCarousel(slider.selector);
      } else if (width > 1080 && sliderLength < settings.perView) {
        disableCarousel(slider.selector);
      } else {
        slider.on('mount.before', () => {
          new Indicators(slider.selector).render()
        })

        slider.mount()
      }
    }
  })
}

export const initTimelineCarousel = () => {
  const itemsLength = document.querySelectorAll("#carousel-timeline .glide__slide").length

  if (itemsLength) {
    const itemsLimit = Math.min(itemsLength, 7)

    const track = new Glide('#carousel-track', {
      perView: 1,
      rewind: false
    }).mount()

    const timeline = new Glide('#carousel-timeline', {
      perView: itemsLimit + 1,
      focusAt: 'center',
      rewind: false,
      breakpoints: { 800: { perView: 4 }, 500: { perView: 3 } }
    }).mount()

    const bullet = new Glide('#carousel-bullet', {
      perView: itemsLength,
      focusAt: 'center',
      rewind: false,
      swipeThreshold: false
    }).mount()

    timeline.on('run', () => {
      track.go('=' + timeline.index)
      bullet.go('=' + timeline.index)
    })

    track.on('run', () => {
      timeline.go('=' + track.index)
      bullet.go('=' + track.index)
    })

    bullet.on('run', () => {
      timeline.go('=' + bullet.index)
      track.go('=' + bullet.index)
    })
  }
}

export const initSubjectCarousel = () => {
  let subject = $("#subject-carousel");
  let budget = $("#budget-carousel");

  subject.owlCarousel({
      items: 1,
      slideSpeed: 2000,
      nav: true,
      autoplay: false, 
      dots: false,
      loop: true,
      slideBy: 1,
      responsiveRefreshRate: 200,
  }).on('changed.owl.carousel', syncPosition);

  budget
    .on('initialized.owl.carousel', function() {
      budget.find(".owl-item").eq(0).addClass("current");
    })
    .owlCarousel({
        autoWidth: true,
        dots: false,
        loop: false,
        nav: false,
        items: 1,
        smartSpeed: 200,
        slideSpeed: 500,
        slideBy: 1,
        responsiveRefreshRate: 100
    }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    let count = el.item.count - 1;
    let current = Math.round(el.item.index - (el.item.count / 2) - .5);

    if (current < 0) {
        current = count;
    }
    if (current > count) {
        current = 0;
    }

    budget
      .find(".owl-item")
      .removeClass("current")
      .eq(current)
      .addClass("current");
    let onscreen = budget.find('.owl-item.active').length - 1;
    let start = budget.find('.owl-item.active').first().index();
    let end = budget.find('.owl-item.active').last().index();

    if (current < start) {
      budget.data('owl.carousel').to(current - onscreen, 100, true);
    } else {
      budget.data('owl.carousel').to(current, 100, true);
    }
  }

  function syncPosition2(el) {
    let number = $(el.target).find('.owl-item.current').index();
    subject.data('owl.carousel').to(number, 100, true);
  }

  budget.on("click", ".owl-item", function(e) {
    e.preventDefault();
    let number = $(this).index();
    subject.data('owl.carousel').to(number, 300, true);
  });
   
}
